import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import i18n from '@/i18n';

Vue.use(VueRouter);

function normalizePathname(pathname) {
  const normalizedPath = decodeURI(pathname)
    // Replaces repeated slashes in the URL.
    .replace(/\/+/g, '/')
    // Reference: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/normalize
    // Note: Missing native IE support, may want to skip this step.
    .normalize();
  return `${normalizedPath}/`;
}

const routes = [
  {
    path: '/:locale',
    component: () => import('@/views/Base.vue'),
    beforeEnter: (to, from, next) => {
    
      const locale = to.params.locale;
      const supported_locales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',');
        
      if (!locale) {
        return next({
          path: `${process.env.VUE_APP_I18N_DEFAULT_LOCALE}${to.path.endsWith('/') ? to.path : normalizePathname(to.path)}`,
          query: to.query
        });
      }
    
      if (locale && locale.length !== 2) {
        return next({
          path: `${process.env.VUE_APP_I18N_DEFAULT_LOCALE}${to.path.endsWith('/') ? to.path : normalizePathname(to.path)}`,
          query: to.query
        });
      }
    
      if (locale && !supported_locales.includes(locale)) {
        return next({
          path:
            `${process.env.VUE_APP_I18N_DEFAULT_LOCALE}${to.path.slice(3).endsWith('/') ?
              to.path.slice(3) :
              normalizePathname(to.path.slice(3))}`,
          query: to.query
        });
      }

      if (!to.path.endsWith('/')) {
        return next({
          path: normalizePathname(to.path),
          query: to.query
        });
      }

      i18n.locale = locale;
    
      if (store.state.reloadIntervalId) {
        store.commit('CLEAR_RELOAD_INTERVAL_ID');
      }
      store.dispatch('user-login/GET_USER_AUTHENTICATION')
        .then(() => {
          const user = store.state['user-login'].userData;
    
          if (to.path.includes('account')) {
            if (user.is_superuser || (user.usergroup_roles && user.usergroup_roles.some(el => el.role === 2))) {
              return next();
            } else {
              return next({ name: '403Page' });
            }
          } else if (to.path.includes('data')) {
            if (user.is_superuser || (user.usergroup_roles && user.usergroup_roles.some(el => el.role >= 1))) {
              return next();
            } else {
              return next({ name: '403Page' });
            }
          } else {
            return next();
          }
        })
        .catch((err) => {
          console.error(err);
        });

      return next();
    
    },
    children: [
      {
        path: '',
        // redirect: { name: 'Home' },  ==> IT DOES NOT WORK!
        beforeEnter: (to, from, next) => {
          return next(`${to.path}home/`);
        }
      },
      {
        path: 'home',
        name: 'Home',
        component: () => import('@/views/Home.vue'),
      },
      // Gestion des comptes
      {
        path: 'accounts',
        name: 'Accounts',
        component: () => import('@/views/Accounts.vue'),
      },
      // Utilisateurs
      {
        path: 'accounts/users',
        name: 'Users',
        component: () => import('@/views/Users/Users.vue'),
      },
      {
        path: 'accounts/users/new',
        name: 'AddUser',
        component: () => import('@/views/Users/AddUser.vue'),
      },
      {
        path: 'accounts/users/:id',
        name: 'UserDetail',
        component: () => import('@/views/Users/UserDetail.vue'),
      },
      // Organisations
      {
        path: 'accounts/organisations',
        name: 'Organisations',
        component: () => import('@/views/Organisations/Organisations.vue'),
      },
      {
        path: 'accounts/organisations/new',
        name: 'AddOrganisation',
        component: () => import('@/views/Organisations/AddOrganisation.vue'),
      },
      {
        path: 'accounts/organisations/:id',
        name: 'OrganisationDetail',
        component: () => import('@/views/Organisations/OrganisationDetail.vue'),
      },
      // Groupes d'utilisateurs
      {
        path: 'accounts/groups',
        name: 'Groups',
        component: () => import('@/views/Groups/Groups.vue'),
      },
      {
        path: 'accounts/groups/new',
        name: 'AddGroup',
        component: () => import('@/views/Groups/AddGroup.vue'),
      },
      {
        path: 'accounts/groups/:id',
        name: 'GroupDetail',
        component: () => import('@/views/Groups/GroupDetail.vue'),
      },
      // Groupes d'organisations
      {
        path: 'accounts/spheres',
        name: 'Spheres',
        component: () => import('@/views/Spheres/Spheres.vue'),
      },
      {
        path: 'accounts/spheres/new',
        name: 'AddSphere',
        component: () => import('@/views/Spheres/AddSphere.vue'),
      },
      {
        path: 'accounts/spheres/:id',
        name: 'SphereDetail',
        component: () => import('@/views/Spheres/SphereDetail.vue'),
      },
      // Données
      {
        path: 'data',
        name: 'Data',
        component: () => import('@/views/Data.vue'),
      },
      // Jeux de données
      {
        path: 'data/datasets',
        name: 'Datasets',
        component: () => import('@/views/Datasets/Datasets.vue'),
      },
      {
        path: 'data/datasets/new',
        name: 'AddDataset',
        component: () => import('@/views/Datasets/AddDataset.vue'),
      },
      {
        path: 'data/datasets/:id',
        name: 'DatasetDetail',
        component: () => import('@/views/Datasets/DatasetDetail.vue'),
      },
      {
        path: 'data/datasets/:id/permissions',
        name: 'DatasetPermissions',
        component: () => import('@/views/Datasets/DatasetPermissions.vue'),
        props: true
      },
      // Logs
      {
        path: 'logs/:object/:id',
        name: 'Logs',
        component: () => import('@/views/Logs/LogsView.vue'),
      },
      // Tasks
      {
        path: 'data/tasks',
        name: 'Tasks',
        component: () => import('@/views/Tasks/Tasks.vue'),
        props: route => ({ datasetId: route.query.dataset })
      },
      {
        path: 'data/tasks/:id',
        name: 'TaskDetail',
        component: () => import('@/views/Tasks/TaskDetail.vue'),
      },
      // Others
      {
        path: 'access-denied/',
        name: '403Page',
        component: () => import('@/views/403Page.vue'),
      },
      {
        path: 'not-found/',
        name: 'NotFound',
        component: () => import('@/views/NotFound.vue'),
      },
    ]
  },
  {
    path: '*',
    name: 'Fallback',
    beforeEnter: (to, from, next) => {
      const matchedRoutes = router.resolve(
        `${process.env.VUE_APP_I18N_DEFAULT_LOCALE}${to.path.endsWith('/') ?
          to.path :
          normalizePathname(to.path)}`
      ).resolved.matched;
      if (
        matchedRoutes && !matchedRoutes.find(el => el.name === 'Fallback')
      ) {
        return next({
          path: `${process.env.VUE_APP_I18N_DEFAULT_LOCALE}${to.path.endsWith('/') ? to.path : normalizePathname(to.path)}`,
          query: to.query
        });
      }
      return next({
        path: 'not-found/',
      });
    }
  },

];

const router = new VueRouter({
  routes,
  mode: 'history',
  base: process.env.VUE_APP_BASE_PATH || '',
});

export default router;
