import axios from 'axios';
import i18n from '@/i18n';

const DEV_AUTH = process.env.NODE_ENV === 'development' ? true : false;

const AUTH = {
  username: process.env.VUE_APP_API_ADMIN_USERNAME,
  password: process.env.VUE_APP_API_ADMIN_PASSWORD
};


const path = require('path');
const DOMAIN = process.env.VUE_APP_DOMAIN;
const USERGROUP_API_PATH = process.env.VUE_APP_USERGROUP_API_PATH;

if (!DEV_AUTH) {
  axios.defaults.headers.common['X-CSRFToken'] = (name => {
    var re = new RegExp(`${name}=([^;]+)`);
    var value = re.exec(document.cookie);
    return (value != null) ? unescape(value[1]) : null;
  })('csrftoken');
}

const usergroupsAPI = {

  async getUsergroupsTypes() {
    const url = new URL(path.join(`${i18n.locale}${USERGROUP_API_PATH}`, 'user-group-types/'), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getUsergroupsList(usergroupTypes, page, filters) {
    let url;
    if (page) {
      url = new URL(path.join(`${i18n.locale}${USERGROUP_API_PATH}`, `user-groups/?page=${page}`), DOMAIN);
    } else {
      url = new URL(path.join(`${i18n.locale}${USERGROUP_API_PATH}`, 'user-groups/'), DOMAIN);
    }
    if (usergroupTypes.length > 0) {
      url.href = url.href.concat('', `${url.href.includes('?') ? '&' : '?'}usergroup_types=${usergroupTypes.join(',')}`);
    }
    if (filters) {
      for (const filter in filters) {
        if (filters[filter] && filters[filter].length) {
          url.href = url.href.concat('', `${url.href.includes('?') ? '&' : '?'}${filter}=${filters[filter].join(',')}`);
        }
      }
    }
    const response = await axios.get(
      url,
      { ...DEV_AUTH && { auth: AUTH } }
    );
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async orderUsergroupsList(direction, field, usergroupTypes, page, filters) {
    let url;
    if (page) {
      url = new URL(path.join(`${i18n.locale}${USERGROUP_API_PATH}`, `user-groups/?ordering=${direction}${field}&page=${page}`), DOMAIN);
    } else {
      url = new URL(path.join(`${i18n.locale}${USERGROUP_API_PATH}`, `user-groups/?ordering=${direction}${field}`), DOMAIN);
    }
    if (usergroupTypes.length > 0) {
      url.href = url.href.concat('', `${url.href.includes('?') ? '&' : '?'}usergroup_types=${usergroupTypes.join(',')}`);
    }
    if (filters) {
      for (const filter in filters) {
        if (filters[filter] && filters[filter].length) {
          url.href = url.href.concat('', `${url.href.includes('?') ? '&' : '?'}${filter}=${filters[filter].join(',')}`);
        }
      }
    }
    const response = await axios.get(
      url,
      { ...DEV_AUTH && { auth: AUTH } }
    );
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getFilteredUsergroupsList(type, page = 1, filters) {
    let url = new URL(path.join(`${i18n.locale}${USERGROUP_API_PATH}`, `user-groups/?page=${page}&usergroup_types=${type}`), DOMAIN);
    if (filters) {
      for (const filter in filters) {
        if (filters[filter] && filters[filter].length) {
          url.href = url.href.concat('', `${url.href.includes('?') ? '&' : '?'}${filter}=${filters[filter].join(',')}`);
        }
      }
    }
    const response = await axios.get(
      url,
      { ...DEV_AUTH && { auth: AUTH } }
    );
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async orderFilteredUsergroupsList(direction, field, type, page = 1, filters) {
    let url = new URL(path.join(`${i18n.locale}${USERGROUP_API_PATH}`, `user-groups/?ordering=${direction}${field}&page=${page}&usergroup_types=${type}`), DOMAIN);
    if (filters) {
      for (const filter in filters) {
        if (filters[filter] && filters[filter].length) {
          url.href = url.href.concat('', `${url.href.includes('?') ? '&' : '?'}${filter}=${filters[filter].join(',')}`);
        }
      }
    }
    const response = await axios.get(
      url,
      { ...DEV_AUTH && { auth: AUTH } }
    );
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getUsergroup(id) {
    const url = new URL(path.join(`${i18n.locale}${USERGROUP_API_PATH}`, `user-groups/${id}/`), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async createUsergroup(data) {
    const url = new URL(path.join(`${i18n.locale}${USERGROUP_API_PATH}`, 'user-groups/'), DOMAIN);
    const response = await axios.post(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 201) {
      return response.data;
    }
    return false;
  },

  async updateUsergroup(id, data) {
    const url = new URL(path.join(`${i18n.locale}${USERGROUP_API_PATH}`, `user-groups/${id}/`), DOMAIN);
    const response = await axios.put(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async patchUsergroup(id, data) {
    const url = new URL(path.join(`${i18n.locale}${USERGROUP_API_PATH}`, `user-groups/${id}/`), DOMAIN);
    const response = await axios.patch(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async deleteUsergroup(id) {
    const url = new URL(path.join(`${i18n.locale}${USERGROUP_API_PATH}`, `user-groups/${id}`), DOMAIN);
    const response = await axios.delete(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 204) {
      return response.data;
    }
    return false;
  },

  async getUsergroupRoles() {
    const url = new URL(path.join(`${i18n.locale}${USERGROUP_API_PATH}`, 'user-group-roles/'), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getUsergroupMembers(id, direction, field, page) {
    let url;
    if (field) {
      url = new URL(path.join(`${i18n.locale}${USERGROUP_API_PATH}`, `user-groups/${id}/members/?ordering=${direction}${field}`), DOMAIN);
      url = url + `&page=${page ? page : 1}`;
    } else {
      url = new URL(path.join(`${i18n.locale}${USERGROUP_API_PATH}`, `user-groups/${id}/members/`), DOMAIN);
      url = url + `?page=${page ? page : 1}`;
    }
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async addUsergroupMember(usergroupId, data) {
    const url = new URL(path.join(`${i18n.locale}${USERGROUP_API_PATH}`, `user-groups/${usergroupId}/members/`), DOMAIN);
    const response = await axios.post(url, data, { ...DEV_AUTH && { auth: AUTH } });
    return response;
  },

  async updateUsergroupMember(usergroupId, userId, data) {
    const url = new URL(path.join(`${i18n.locale}${USERGROUP_API_PATH}`, `user-groups/${usergroupId}/members/${userId}/`), DOMAIN);
    const response = await axios.put(url, data, { ...DEV_AUTH && { auth: AUTH } });
    return response;
  },

  async removeUsergroupMember(usergroupId, userId) {
    const url = new URL(path.join(`${i18n.locale}${USERGROUP_API_PATH}`, `user-groups/${usergroupId}/members/${userId}/`), DOMAIN);
    const response = await axios.delete(url, { ...DEV_AUTH && { auth: AUTH } });
    return response;
  },

  async getUsergroupChildren(id, direction, field) {
    let url;
    if (field) {
      url = new URL(path.join(`${i18n.locale}${USERGROUP_API_PATH}`, `user-groups/${id}/children/?ordering=${direction}${field}`), DOMAIN);
    } else {
      url = new URL(path.join(`${i18n.locale}${USERGROUP_API_PATH}`, `user-groups/${id}/children/`), DOMAIN);
    }
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getUserStatuses() {
    try {
      const url = new URL(path.join(`${i18n.locale}${USERGROUP_API_PATH}`, 'user-status/'), DOMAIN);
      const response = await axios.get(url);
      if (response.status === 200) {
        return response.data;
      }
      return false;
    } catch {
      return false;
    }
  },

  async getUsergroupStatuses() {
    try {
      const url = new URL(path.join(`${i18n.locale}${USERGROUP_API_PATH}`, 'user-group-status/'), DOMAIN);
      const response = await axios.get(url);
      if (response.status === 200) {
        return response.data;
      }
      return false;
    } catch {
      return false;
    }
  },

  async getUsergroupThumbnail(id) {
    const url = new URL(path.join(`${i18n.locale}${USERGROUP_API_PATH}`, `user-groups/${id}/thumbnail/`), DOMAIN);
    const response = await axios.get(url, {
      ...DEV_AUTH && { auth: AUTH },
      responseType: 'blob'
    });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },
  async setUsergroupThumbnail(id, data) {
    const url = new URL(path.join(`${i18n.locale}${USERGROUP_API_PATH}`, `user-groups/${id}/thumbnail/`), DOMAIN);
    const response = await axios.put(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },
  async deleteUsergroupThumbnail(id) {
    const url = new URL(path.join(`${i18n.locale}${USERGROUP_API_PATH}`, `user-groups/${id}/thumbnail/`), DOMAIN);
    const response = await axios.delete(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 204) {
      return response.data;
    }
    return false;
  },
};

export default usergroupsAPI;
