import datasetsAPI from '@/api/datasetsAPI.js';
import { ErrorService } from '@/services/error-service.js';
import { donwloadFileFromStream } from '@/utils/download.js';
import axios from 'axios';
import i18n from '@/i18n';

const DEV_AUTH = process.env.NODE_ENV === 'development' ? true : false;
const AUTH = {
  username: process.env.VUE_APP_API_ADMIN_USERNAME,
  password: process.env.VUE_APP_API_ADMIN_PASSWORD
};
const path = require('path');
const DOMAIN = process.env.VUE_APP_DOMAIN;
const DATASET_API_PATH = process.env.VUE_APP_DATASET_API_PATH;

/**************** STATE *******************/
const state = {
  // list
  datasetsCount: 0,
  datasetsEditable: 0,
  datasetsViewable: 0,
  datasetsList: [],

  // current
  currentDataset: {},
  currentDatasetUsergroup: null,
  currentDatasetScenarios: [],

  // form
  datasetsCategories: [],
  datasetsGranularities: [],
  datasetsLicences: [],
  datasetsTopics: [],
  datasetsKinds: [],
  datasetsRestrictions: [],
  datasetsUpdateFrequencies: [],
  datasetsRecordTypes: [],
  datasetsRoles: [],
  currentDatasetRoles: [],
  datasetsError: null,
  lastCreatedDataset: null,
  duplicatedDataset: null,
  duplicatedDatasetRoles: [],

  // permissions
  currentUsergroupsPermissionsCount: 0,
  currentUsergroupsPermissions: [],
  currentUsergroupsPermissionsFilters: {
    usergroup_type_id: [],
    organisation_type_id: [],
    level: []
  },

  // search and filter
  currentDatasetsFilters: {
    perms: 'change_dataset'
  },
  searchDatasetsFilter: null,
  isDatasetsListSearched: null,
  isPermissionsListSearched: false,
  searchPermissionsFilter: null,
  currentUsergroupsPermissionsOrdering: {
    direction: null,
    field: null
  },
  currentDatasetsOrdering: {
    direction: null,
    field: null
  },
  selectedPermissions: []
};

/**************** GETTERS *****************/
const getters = {
  // currentDatasetMainResource: (state) => {
  //   if (state.currentDataset && state.currentDataset.resource_main) {
  //     return state.currentDataset.resource_main;
  //   }
  //   return null;
  // },
  currentDatasetAnnexResources: (state) => {
    if (state.currentDataset && state.currentDataset.resource_annexes) {
      return state.currentDataset.resource_annexes;
    }
    return null;
  },
  currentDatasetPublishState: (state) => {
    if (typeof state.currentDataset.publish === 'undefined' || state.currentDataset.publish === null) {
      return true;
    }
    return state.currentDataset.publish;
  },
  currentDatasetPublishScenario: (state) => {
    return state.currentDatasetScenarios.find(el => el.codename === 'publish-dataset');
  },
  currentDatasetUnpublishScenario: (state) => {
    return state.currentDatasetScenarios.find(el => el.codename === 'unpublish-dataset');
  },
  publishDatasetScenariosFound(state, getters) {
    return (getters.currentDatasetPublishScenario && getters.currentDatasetPublishScenario.id) &&
      (getters.currentDatasetUnpublishScenario && getters.currentDatasetUnpublishScenario.id);
  },
  currentDatasetExtras: (state) => {
    return state.currentDataset.extras && state.currentDataset.extras.length ?
      state.currentDataset.extras : null;
  },
  tablePage: (state, getters, rootState) => {
    try {
      return rootState.table.currentTables.find(t => t.name === 'datasetsListTable').currentPage;
    } catch {
      return 1;
    }
  }
};

/*************** MUTATIONS ****************/
const mutations = {
  SET_DATASETS_COUNT: (state, payload) => {
    state.datasetsCount = payload.count;
    state.datasetsEditable = payload.editable;
    state.datasetsViewable = payload.viewable;
  },
  SET_DATASETS_LIST: (state, payload) => {
    if (payload && payload.results) {
      state.datasetsList = payload.results;
    }
    if (payload && payload.count !== null) {
      state.datasetsCount = payload.count;
    }
  },
  SET_CURRENT_DATASET: (state, payload) => {
    if (payload) {
      state.currentDataset = payload;
    } else {
      state.currentDataset = {};
    }
  },
  SET_CURRENT_DATASET_USERGROUP: (state, payload) => {
    if (payload) {
      state.currentDatasetUsergroup = payload;
    } else {
      state.currentDatasetUsergroup = null;
    }
  },
  SET_CURRENT_DATASET_SCENARIOS: (state, payload) => {
    if (payload) {
      state.currentDatasetScenarios = payload;
    } else {
      state.currentDatasetScenarios = [];
    }
    if (!state.currentDatasetScenarios.find(el => el.codename === 'publish-dataset')) {
      ErrorService.onError(null, 'Scénario de publication du jeu de données manquant.');
    }
    if (!state.currentDatasetScenarios.find(el => el.codename === 'unpublish-dataset')) {
      ErrorService.onError(null, 'Scénario de dépublication du jeu de données manquant.');
    }
  },

  SET_CURRENT_USERGROUPS_PERMISSIONS: (state, payload) => {
    state.currentUsergroupsPermissionsCount = payload.count;
    state.currentUsergroupsPermissions = payload.results;
  },
  SET_DATASETS_CATEGORIES: (state, payload) => {
    state.datasetsCategories = payload;
  },
  SET_DATASETS_GRANULARITIES: (state, payload) => {
    state.datasetsGranularities = payload;
  },
  SET_DATASETS_LICENCES: (state, payload) => {
    state.datasetsLicences = payload;
  },
  SET_DATASETS_TOPICS: (state, payload) => {
    state.datasetsTopics = payload;
  },
  SET_DATASETS_KINDS: (state, payload) => {
    state.datasetsKinds = payload;
  },
  SET_DATASETS_RESTRICTIONS: (state, payload) => {
    state.datasetsRestrictions = payload;
  },
  SET_DATASETS_UPDATE_FREQUENCIES: (state, payload) => {
    state.datasetsUpdateFrequencies = payload;
  },
  SET_DATASETS_RECORD_TYPES: (state, payload) => {
    if (payload && payload.length) {
      state.datasetsRecordTypes = payload.sort((a, b) => a.label.localeCompare(b.label));
    } else {
      state.datasetsRecordTypes = [];
    }
  },
  SET_DATASETS_ROLES: (state, payload) => {
    state.datasetsRoles = payload;
  },
  SET_CURRENT_DATASET_ROLES: (state, payload) => {
    if (payload) {
      state.currentDatasetRoles = payload.results;
    } else {
      state.currentDatasetRoles = [];
    }
  },

  SET_ERROR: (state, error) => {
    if (error) {
      ErrorService.onError(error);
      state.datasetsError = error;
    } else {
      state.datasetsError = error;
    }
  },
  SET_LAST_CREATED_DATASET: (state, payload) => {
    state.lastCreatedDataset = payload;
  },
  SET_DUPLICATED_DATASET: (state, payload) => {
    state.duplicatedDataset = payload;
  },
  SET_DUPLICATED_DATASET_ROLES: (state, payload) => {
    state.duplicatedDatasetRoles = payload;
  },
  SET_DATASETS_FILTERS: (state, payload) => {
    if (payload && payload.value !== null) {
      state.currentDatasetsFilters[payload.filter] = payload.value;
    } else {
      delete state.currentDatasetsFilters[payload.filter];
    }
  },
  RESET_DATASETS_FILTERS: (state) => {
    state.currentDatasetsFilters = {
      perms: 'change_dataset'
    };
  },
  SET_USERGROUPS_PERMISSIONS_FILTERS: (state, payload) => {
    if (payload.filter === 'level') {
      state.currentUsergroupsPermissionsFilters[payload.filter] = payload.value;
    } else if (state.currentUsergroupsPermissionsFilters[payload.filter].findIndex(el => el === payload.value) === -1) {
      if (payload.filter === 'organisation_type_id') {
        state.currentUsergroupsPermissionsFilters[payload.filter] = [payload.value];
      } else {
        state.currentUsergroupsPermissionsFilters[payload.filter].push(payload.value);
      }
    }
  },
  REMOVE_USERGROUPS_PERMISSIONS_FILTERS: (state, payload) => {
    state.currentUsergroupsPermissionsFilters[payload.filter.filter].splice(payload.index, 1);
  },
  RESET_USERGROUPS_PERMISSIONS_FILTERS: (state, payload) => {
    state.currentUsergroupsPermissionsFilters[payload] = [];
  },

  SET_IS_PERMISSIONS_LIST_SEARCHED: (state, payload) => {
    state.isPermissionsListSearched = payload.isSearched;
    state.searchPermissionsFilter = payload.text;
  },

  SET_USERGROUPS_PERMISSIONS_ORDERING: (state, payload) => {
    state.currentUsergroupsPermissionsOrdering = payload;
  },

  SET_IS_DATASETSLIST_SEARCHED: (state, payload) => {
    state.isDatasetsListSearched = payload.isSearched;
    state.searchDatasetsFilter = payload.text;
  },

  SET_DATASETS_ORDERING: (state, payload) => {
    state.currentDatasetsOrdering = payload;
  },

  SET_SELECTED_PERMISSIONS: (state, payload) => {
    if (payload.selected) {
      const index = state.selectedPermissions.findIndex(el => el.id === payload.item.id);
      if (index === -1) {
        state.selectedPermissions.push(payload.item);
      }
    } else {
      const index = state.selectedPermissions.findIndex(el => el.id === payload.item.id);
      if (index !== -1) {
        state.selectedPermissions.splice(index, 1);
      }
    }
  },
  RESET_SELECTED_PERMISSIONS: (state) => {
    state.selectedPermissions.splice(0);
  }
};
/**************** ACTIONS *****************/
const actions = {
  // PRESET_DATASETS_FILTERS: ({ commit }, filter) => {
  //   commit('table/SET_CURRENT_PAGE', { name: 'datasetsListTable', page: 1 }, { root: true });
  //   commit('SET_DATASETS_FILTERS', filter);
  // },
  PRE_SET_USERGROUPS_PERMISSIONS_FILTERS: ({ state, commit, dispatch }, payload) => {
    if (payload.filter === 'level') {
      commit('table/SET_CURRENT_PAGE', { name: 'datasetPermissionsTable', page: 1 }, { root: true });
      commit('SET_USERGROUPS_PERMISSIONS_FILTERS', payload);
      dispatch('SEARCH_DATASET_USERGROUPS_PERMISSIONS', { text: state.searchPermissionsFilter });
    } else if (state.currentUsergroupsPermissionsFilters[payload.filter].findIndex(el => el === payload.value) === -1) {
      if (payload.filter === 'organisation_type_id') {
        commit('table/SET_CURRENT_PAGE', { name: 'datasetPermissionsTable', page: 1 }, { root: true });
        commit('SET_USERGROUPS_PERMISSIONS_FILTERS', payload);
        dispatch('SEARCH_DATASET_USERGROUPS_PERMISSIONS', { text: state.searchPermissionsFilter });
      } else {
        commit('table/SET_CURRENT_PAGE', { name: 'datasetPermissionsTable', page: 1 }, { root: true });
        commit('SET_USERGROUPS_PERMISSIONS_FILTERS', payload);
      }
    }
  },
  PRE_REMOVE_USERGROUPS_PERMISSIONS_FILTERS: ({ state, commit }, payload) => {
    const index = state.currentUsergroupsPermissionsFilters[payload.filter].findIndex(el => el === payload.value);
    if (index !== -1) {
      commit('REMOVE_USERGROUPS_PERMISSIONS_FILTERS', { index: index, filter: payload });
    }
  },
  PRE_RESET_USERGROUPS_PERMISSIONS_FILTERS: ({ commit }, payload) => {
    commit('table/SET_CURRENT_PAGE', { name: 'datasetPermissionsTable', page: 1 }, { root: true });
    commit('RESET_USERGROUPS_PERMISSIONS_FILTERS', payload);
  },

  GET_DATASET_STATIC_DATA: async ({ state, dispatch }) => {
    if (state.datasetsCategories.length === 0) {
      await dispatch('GET_DATASETS_CATEGORIES');
    }
    if (state.datasetsGranularities.length === 0) {
      await dispatch('GET_DATASETS_GRANULARITIES');
    }
    if (state.datasetsLicences.length === 0) {
      await dispatch('GET_DATASETS_LICENCES');
    }
    if (state.datasetsTopics.length === 0) {
      await dispatch('GET_DATASETS_TOPICS');
    }
    if (state.datasetsKinds.length === 0) {
      await dispatch('GET_DATASETS_KINDS');
    }
    if (state.datasetsRestrictions.length === 0) {
      await dispatch('GET_DATASETS_RESTRICTIONS');
    }
    if (state.datasetsUpdateFrequencies.length === 0) {
      await dispatch('GET_DATASETS_UPDATE_FREQUENCIES');
    }
    if (state.datasetsRecordTypes.length === 0) {
      await dispatch('GET_DATASETS_RECORD_TYPES');
    }
  },

  GET_DATASETS_COUNT: async({ commit }) => {
    const datasetsCount = await datasetsAPI.getDatasetsCount();
    commit('SET_DATASETS_COUNT', datasetsCount);
  },
  GET_DATASETS_LIST: async (
    { state, rootState, getters, commit, dispatch },
    {
      direction,
      field,
      page,
      usergroup,
      recordTypes
    }
  ) => {
    let datasets;
    if (!page) {
      page = getters.tablePage;
    }
    // Save sorting params
    commit('SET_DATASETS_ORDERING', { direction, field });

    if (rootState.abortControllers.length > 0) {
      commit('USE_ABORT_CONTROLLER', 'abort_search_datasets', { root: true });
    }
    const controller = new AbortController();
    commit('SET_ABORT_CONTROLLER', {
      id: 'abort_search_datasets',
      controller: controller
    }, { root: true });

    if (state.isDatasetsListSearched) {
      await dispatch('SEARCH_DATASETS_LIST', {
        text: state.searchDatasetsFilter,
        usergroup: usergroup,
        recordTypes: recordTypes
      });
    } else if (field) {
      datasets = await datasetsAPI.orderDatasetsList(
        direction,
        field,
        page,
        usergroup,
        recordTypes,
        state.currentDatasetsFilters,
        controller
      );
      commit('REMOVE_ABORT_CONTROLLER', 'abort_search_datasets', { root: true });
    } else {
      datasets = await datasetsAPI.getDatasetsList(
        page,
        usergroup,
        recordTypes,
        state.currentDatasetsFilters,
        controller
      );
      commit('REMOVE_ABORT_CONTROLLER', 'abort_search_datasets', { root: true });
    }
    commit('SET_DATASETS_LIST', datasets);
  },

  SEARCH_DATASETS_LIST: async (
    { state, rootState, commit, dispatch },
    { text, ordering, usergroup, recordTypes }
  ) => {
    if (!ordering) {
      ordering = state.currentDatasetsOrdering;
    }
    if (text) {
      const searched = await dispatch('HANDLE_DATASETS_SEARCH_REQUEST', {
        text,
        ordering,
        usergroup,
        recordTypes
      });
      return searched;
    } else {
      if (rootState.abortControllers.length > 0) {
        commit('USE_ABORT_CONTROLLER', 'abort_search_datasets', { root: true });
      }
      commit('SET_IS_DATASETSLIST_SEARCHED', {
        isSearched: false,
        text: null
      });
      await dispatch('GET_DATASETS_LIST', { ...ordering, usergroup, recordTypes });
      return true;
    }
  },

  HANDLE_DATASETS_SEARCH_REQUEST: async (
    { rootState, getters, commit },
    {
      text,
      ordering,
      usergroup,
      recordTypes
    }
  ) => {
    if (rootState.abortControllers.length > 0) {
      commit('USE_ABORT_CONTROLLER', 'abort_search_datasets', { root: true });
    }
    const controller = new AbortController();
    commit('SET_ABORT_CONTROLLER', {
      id: 'abort_search_datasets',
      controller: controller
    }, { root: true });

    let url = new URL(
      path.join(
        `${i18n.locale}${DATASET_API_PATH}`,
        `datasets/?page=${getters.tablePage}&search=${text}`
      ),
      DOMAIN
    );
    if (ordering.field) {
      url.href = url.href.concat('', `&ordering=${ordering.direction}${ordering.field}`);
    }
    if (usergroup) {
      url.href = url.href.concat('', `&usergroup_id=${usergroup}`);
    }
    if (recordTypes) {
      url.href = url.href.concat('', `&record_type=${recordTypes.join(',')}`);
    }
    for (const filter in state.currentDatasetsFilters) {
      url.href = url.href.concat('', `&${filter}=${state.currentDatasetsFilters[filter]}`);
    }

    try {
      const response = await axios.get(
        url,
        {
          signal: controller.signal,
          ...DEV_AUTH && { auth: AUTH }
        }
      );
      if (response.status === 200) {
        const datasets = response.data;
        if (datasets) {
          commit('SET_ERROR', null);
          commit('SET_DATASETS_LIST', datasets);
          commit('SET_IS_DATASETSLIST_SEARCHED', {
            isSearched: true,
            text: text
          });
        }
        commit('REMOVE_ABORT_CONTROLLER', 'abort_search_datasets', { root: true });
        return true;
      }
      return false;
    } catch(err) {
      if (err && err.code && err.code !== 'ERR_CANCELED') {
        commit('SET_ERROR', err);
        commit('REMOVE_ABORT_CONTROLLER', 'abort_search_datasets', { root: true });
        return true;
      }
      return false;
    }
  },

  EXPORT_DATASET_LIST: async ({ state }) => {
    let url = new URL(
      path.join(
        `${i18n.locale}${DATASET_API_PATH}`,
        'datasets/export/odl/'
      ),
      DOMAIN
    );
    if (state.isDatasetsListSearched) {
      url.href = url.href.concat(
        '',
        `${url.href.includes('?') ? '&' : '?'}search=${state.searchDatasetsFilter}`
      );
    }
    if (state.currentDatasetsFilters && Object.keys(state.currentDatasetsFilters).length) {
      for (const filter in state.currentDatasetsFilters) {
        url.href = url.href.concat(
          '',
          `${url.href.includes('?') ? '&' : '?'}${filter}=${state.currentDatasetsFilters[filter]}`
        );
      }
    }
    if (state.currentDatasetsOrdering && state.currentDatasetsOrdering.field) {
      url.href = url.href.concat(
        '',
        `${url.href.includes('?') ? '&' : '?'}ordering=${state.currentDatasetsOrdering.direction}${state.currentDatasetsOrdering.field}`
      );
    }

    try {
      const response = await axios.get(
        url,
        {
          ...DEV_AUTH && { auth: AUTH },
        }
      );
      if (response.status === 200) {
        donwloadFileFromStream(response);
      }
    } catch (err) {
      console.error(err);
    }
  },

  UPDATE_CURRENT_DATASET_USERGROUP: ({ commit, dispatch }, usergroup) => {
    commit('SET_CURRENT_DATASET_USERGROUP', usergroup);
    if (usergroup && usergroup.id) {
      dispatch('resources/GET_FTP_CHOICES', usergroup.id, { root: true });
    } else {
      commit('resources/SET_FTP_CHOICES', null, { root: true });
    }
  },

  GET_DATASET_DETAIL: async ({ commit }, id) => {
    const dataset = await datasetsAPI.getDataset(id);
    commit('SET_CURRENT_DATASET', dataset);
    const scenarios = await datasetsAPI.getDatasetScenarios(id);
    commit('SET_CURRENT_DATASET_SCENARIOS', scenarios);
  },

  DUPLICATE_DATASET: async ({ commit }, id) => {
    const dataset = await datasetsAPI.getDataset(id);
    commit('SET_DUPLICATED_DATASET', dataset);
    const roles = await datasetsAPI.getCurrentDatasetRoles(id);
    commit('SET_DUPLICATED_DATASET_ROLES', roles.results);
  },
  GET_DATASET_USERGROUPS_PERMISSIONS: async ({ state, getters, commit }, { direction, field, id, page }) => {

    // Save sorting params
    commit('SET_USERGROUPS_PERMISSIONS_ORDERING', { direction, field });

    if (!page) {
      page = getters.tablePage;
    }
    if (!id) {
      id = state.currentUsergroupsPermissions[0] ?
        state.currentUsergroupsPermissions[0].dataset.toString() :
        state.currentDataset.id.toString();
    }
    let filters = JSON.parse(JSON.stringify(state.currentUsergroupsPermissionsFilters));
    if (state.searchPermissionsFilter) {
      filters.search = [state.searchPermissionsFilter];
    }
    if (field) {
      await datasetsAPI.orderDatasetPermissions(direction, field, id, filters, page)
        .then((permissions) => {
          if (permissions) {
            commit('SET_ERROR', null);
            commit('SET_CURRENT_USERGROUPS_PERMISSIONS', permissions);
          }
        })
        .catch((error) => {
          console.error(error);
          commit('SET_ERROR', error);
        });
    } else  {
      await datasetsAPI.getDatasetPermissions(id, filters, page)
        .then((permissions) => {
          if (permissions) {
            commit('SET_ERROR', null);
            commit('SET_CURRENT_USERGROUPS_PERMISSIONS', permissions);
          }
        })
        .catch((error) => {
          console.error(error);
          commit('SET_ERROR', error);
        });
    }
  },

  SEARCH_DATASET_USERGROUPS_PERMISSIONS: async (
    { state, rootState, commit, dispatch }, { text, ordering, unfiltered = false }
  ) => {
    if (!ordering) {
      ordering = state.currentUsergroupsPermissionsOrdering;
    }
    if (text) {
      const searched = await dispatch('HANDLE_PERMISSIONS_SEARCH_REQUEST', { text, ordering, unfiltered });
      return searched;
    } else {
      if (rootState.abortControllers.length > 0) {
        commit('USE_ABORT_CONTROLLER', 'abort_search_permissions', { root: true });
      }
      commit('SET_IS_PERMISSIONS_LIST_SEARCHED', {
        isSearched: false,
        text: null
      });
      await dispatch('GET_DATASET_USERGROUPS_PERMISSIONS', { ...ordering });
      return true;
    }
  },
  HANDLE_PERMISSIONS_SEARCH_REQUEST: async (
    { state, rootState, getters, commit },
    { text, ordering, unfiltered = false }
  ) => {

    if (rootState.abortControllers.length > 0) {
      commit('USE_ABORT_CONTROLLER', 'abort_search_permissions', { root: true });
    }
    const controller = new AbortController();
    commit('SET_ABORT_CONTROLLER', {
      id: 'abort_search_permissions',
      controller: controller
    }, { root: true });

    let url;
    if (ordering.field) {
      url = new URL(
        path.join(
          `${i18n.locale}${DATASET_API_PATH}`,
          `usergroup-permissions/?dataset_id=${state.currentDataset.id}&ordering=${ordering.direction}${ordering.field}&page=${getters.tablePage}&search=${text}`
        ),
        DOMAIN
      );
    } else {
      url = new URL(
        path.join(
          `${i18n.locale}${DATASET_API_PATH}`,
          `usergroup-permissions/?dataset_id=${state.currentDataset.id}&page=${getters.tablePage}&search=${text}`
        ),
        DOMAIN
      );
    }

    // Add filters to url
    const filters = state.currentUsergroupsPermissionsFilters;
    if (!unfiltered) {
      for (const filter in filters) {
        if (filters[filter] && filter !== 'search') {
          url.href = url.href.concat('', `&${filter}=${filters[filter].join(',')}`);
        }
      }
    }

    try {
      const response = await axios.get(
        url,
        {
          signal: controller.signal,
          ...DEV_AUTH && { auth: AUTH }
        }
      );
      if (response.status === 200) {
        const usergroupsPermissions = response.data;
        if (usergroupsPermissions) {
          commit('SET_ERROR', null);
          commit('SET_CURRENT_USERGROUPS_PERMISSIONS', usergroupsPermissions);
          commit('SET_IS_PERMISSIONS_LIST_SEARCHED', {
            isSearched: true,
            text: text
          });
        }
        commit('REMOVE_ABORT_CONTROLLER', 'abort_search_permissions', { root: true });
        return true;
      }
      return false;
    } catch(err) {
      if (err && err.code && err.code !== 'ERR_CANCELED') {
        if (err && err.message) {
          console.error(err.message);
        }
        commit('REMOVE_ABORT_CONTROLLER', 'abort_search_permissions', { root: true });
        return true;
      }
      return false;
    }
  },

  SET_DATASET_USERGROUPS_PERMISSIONS: async ({ commit }, { level, data }) => {
    await datasetsAPI.setDatasetPermissions(level, data)
      .then((resp) => {
        if (resp) {
          commit('SET_ERROR', null);
          ErrorService.onSuccess(resp, 'Permission mise à jour.');
        }
      })
      .catch((error) => {
        console.error(error);
        commit('SET_ERROR', error);
      });
  },
  GET_DATASETS_CATEGORIES: async ({ commit }) => {
    const categories = await datasetsAPI.getDatasetsCategories();
    commit('SET_DATASETS_CATEGORIES', categories);
  },
  GET_DATASETS_GRANULARITIES: async ({ commit }) => {
    const granularities = await datasetsAPI.getDatasetsGranularities();
    commit('SET_DATASETS_GRANULARITIES', granularities);
  },
  GET_DATASETS_LICENCES: async ({ commit }) => {
    const licences = await datasetsAPI.getDatasetsLicences();
    commit('SET_DATASETS_LICENCES', licences);
  },
  GET_DATASETS_TOPICS: async ({ commit }) => {
    const topics = await datasetsAPI.getDatasetsTopics();
    commit('SET_DATASETS_TOPICS', topics);
  },
  GET_DATASETS_KINDS: async ({ commit }) => {
    const kinds = await datasetsAPI.getDatasetsKinds();
    commit('SET_DATASETS_KINDS', kinds);
  },
  GET_DATASETS_RESTRICTIONS: async ({ commit }) => {
    const restrictions = await datasetsAPI.getDatasetsRestrictions();
    commit('SET_DATASETS_RESTRICTIONS', restrictions);
  },
  GET_DATASETS_UPDATE_FREQUENCIES: async ({ commit }) => {
    const updateFrequencies = await datasetsAPI.getDatasetsUpdateFrequencies();
    commit('SET_DATASETS_UPDATE_FREQUENCIES', updateFrequencies);
  },
  GET_DATASETS_RECORD_TYPES: async ({ commit }) => {
    const recordTypes = await datasetsAPI.getDatasetsRecordTypes();
    commit('SET_DATASETS_RECORD_TYPES', recordTypes);
  },
  GET_DATASETS_ROLES: async ({ commit }) => {
    const roles = await datasetsAPI.getDatasetsRoles();
    commit('SET_DATASETS_ROLES', roles);
  },
  GET_CURRENT_DATASET_ROLES: async ({ commit }, id) => {
    const roles = await datasetsAPI.getCurrentDatasetRoles(id);
    commit('SET_CURRENT_DATASET_ROLES', roles);
  },

  CREATE_DATASET: async ({ getters, commit }, data) => {
    try {
      const createdDataset = await datasetsAPI.createDataset(data);
      if (createdDataset && data.thumbnail) {
        let datasetThumbnail;
        if (data.thumbnail instanceof FormData) {
          datasetThumbnail = await datasetsAPI.setDatasetThumbnail(createdDataset.id, data.thumbnail);
        } else if (data.thumbnail && data.thumbnail.url) {
          const blobresponse = await axios.get(new URL(data.thumbnail.url, process.env.VUE_APP_DOMAIN), { responseType: 'blob' });
          if (blobresponse && blobresponse.status === 200) {
            const formData = new FormData();
            const name = data.thumbnail.name.split('/')[1];
            formData.append('file', blobresponse.data, name);
            datasetThumbnail = await datasetsAPI.setDatasetThumbnail(createdDataset.id, formData);
          }
        }
        if (datasetThumbnail) {
          commit('SET_ERROR', null);
          ErrorService.onSuccess(createdDataset, `Le jeu de données <b>${createdDataset.display_name}</b> a été créé avec succès.`);
          commit('SET_LAST_CREATED_DATASET', createdDataset);
        }
      } else if (createdDataset) {
        commit('SET_ERROR', null);
        ErrorService.onSuccess(createdDataset, `Le jeu de données <b>${createdDataset.display_name}</b> a été créé avec succès.`);
        commit('SET_LAST_CREATED_DATASET', createdDataset);
      }
      if (createdDataset) {
        const scenarios = await datasetsAPI.getDatasetScenarios(createdDataset.id);
        commit('SET_CURRENT_DATASET_SCENARIOS', scenarios);
        if (getters.currentDatasetPublishScenario && getters.currentDatasetPublishScenario.id) {
          await datasetsAPI.buildDatasetIndex(createdDataset.id, getters.currentDatasetPublishScenario.id);
        }
      }
    } catch (error) {
      console.error(error);
      ErrorService.onError(error);
      commit('SET_ERROR', error);
    }
  },
  SET_DATASET_THUMBNAIL: async ({ commit }, { id, data }) => {
    await datasetsAPI.setDatasetThumbnail(id, data)
      .then((resp) => {
        if (resp) {
          commit('SET_ERROR', null);
        }
      })
      .catch((error) => {
        commit('SET_ERROR', error);
      });
  },
  UPDATE_DATASET: async ({ getters, commit }, { id, data }) => {
    let scenarioId = null;
    if (getters.publishDatasetScenariosFound) {
      scenarioId = getters.currentDatasetPublishState ?
        getters.currentDatasetPublishScenario.id :
        getters.currentDatasetUnpublishScenario.id;
    }
    await datasetsAPI.updateDataset(id, data, scenarioId)
      .then((resp) => {
        if (resp) {
          commit('SET_ERROR', null);
          ErrorService.onSuccess(resp, 'Le jeu de données a été modifié avec succès.');
        }
      }).catch((error) => {
        commit('SET_ERROR', error);
      });
  },
  PATCH_DATASET: async ({ commit }, { id, data }) => {
    await datasetsAPI.patchDataset(id, data).then((resp) => {
      if (resp) {
        commit('SET_ERROR', null);
        ErrorService.onSuccess(resp, 'Le jeu de données a été modifié avec succès.');
      }
    }).catch((error) => {
      commit('SET_ERROR', error);
    });
  },
  DELETE_DATASET: async ({ commit }, dataset) => {
    try {
      const deletedDataset = await datasetsAPI.deleteDataset(dataset.id);
      if (deletedDataset) {
        commit('SET_ERROR', null);
        ErrorService.onSuccess(true, `Le dataset <b>${dataset.display_name}</b> a été supprimé avec succès.`);
      }
    } catch (err) {
      commit('SET_ERROR', err);
      ErrorService.onError(null, 'Une erreur est survenue.');
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
