import dashboardAPI from '@/api/dashboardAPI.js';

/**************** STATE *******************/
const state = {
  dashboardTasksCount: null,
  dashboardTasksQueue: [],
  currentTask: null
};

/**************** GETTERS *****************/
const getters = {
  tablePage: (state, getters, rootState) => {
    try {
      return rootState.table.currentTables.find(t => t.name === 'tasksListTable').currentPage;
    } catch {
      return 1;
    }
  }
};

/*************** MUTATIONS ****************/
const mutations = {
  SET_DASHBOARD_TASKS: (state, payload) => {
    state.dashboardTasksQueue = payload.results;
    state.dashboardTasksCount = payload.count;
  },

  SET_CURRENT_TASK: (state, payload) => {
    state.currentTask = payload;
  }
};
/**************** ACTIONS *****************/
const actions = {
  GET_DASHBOARD_TASKS: async (
    { rootState, commit, getters },
    { direction, field, page, datasetId, cancellable = true }
  ) => {
    let tasksQueue;
    let controller;

    if (rootState.abortControllers.length > 0) {
      commit('USE_ABORT_CONTROLLER', 'abort_get_dashboard_tasks', { root: true });
    }

    if (cancellable) {
      controller = new AbortController();
      commit('SET_ABORT_CONTROLLER', {
        id: 'abort_get_dashboard_tasks',
        controller: controller
      }, { root: true });
    }

    if (!page) {
      page = getters.tablePage;
    }
    if (field) {
      tasksQueue = await dashboardAPI.orderTaskQueue(direction, field, page, datasetId, controller);
    } else {
      tasksQueue = await dashboardAPI.getTaskQueue(page, datasetId, controller);
    }
    commit('SET_DASHBOARD_TASKS', tasksQueue);
    commit('REMOVE_ABORT_CONTROLLER', 'abort_get_dashboard_tasks', { root: true });
  },

  GET_TASK: async ({ commit }, { id }) => {
    const task = await dashboardAPI.getTask(id);
    commit('SET_CURRENT_TASK', task);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
