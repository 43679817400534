import axios from 'axios';
import i18n from '@/i18n';
import { getUploadProgress } from '@/utils';

const DEV_AUTH = process.env.NODE_ENV === 'development' ? true : false;

const AUTH = {
  username: process.env.VUE_APP_API_ADMIN_USERNAME,
  password: process.env.VUE_APP_API_ADMIN_PASSWORD
};

const path = require('path');
const DOMAIN = process.env.VUE_APP_DOMAIN;
const RESOURCE_API_PATH = process.env.VUE_APP_RESOURCE_API_PATH;
const FILE_UPLOAD_API_APTH = path.join(`${i18n.locale}${RESOURCE_API_PATH}`, 'file-upload/');

if (!DEV_AUTH) {
  axios.defaults.headers.common['X-CSRFToken'] = (name => {
    var re = new RegExp(name + '=([^;]+)');
    var value = re.exec(document.cookie);
    return (value != null) ? unescape(value[1]) : null;
  })('csrftoken');
}

const fileUploadAPI = {

  /** CREATE */
  async createFileUploadFormat(data) {
    const url = new URL(FILE_UPLOAD_API_APTH, DOMAIN);
    const response = await axios.post(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 201) {
      return response.data;
    }
    return false;
  },
  async createFileUploadFile(id, file) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `file-upload/${id}/`), DOMAIN);
    const response = await axios.put(url, file, {
      ...DEV_AUTH && { auth: AUTH },
      onUploadProgress: (progressEvent) => { getUploadProgress(progressEvent); }
    });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  /** UPDATE */
  async updateFileUploadFormat(location, data) {
    const url = new URL(location, DOMAIN);
    const response = await axios.patch(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },
  async updateFileUploadFile(location, file) {
    const url = new URL(location, DOMAIN);
    const response = await axios.put(url, file, {
      ...DEV_AUTH && { auth: AUTH },
      onUploadProgress: (progressEvent) => { getUploadProgress(progressEvent); }
    });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },
  async updateFileUploadResource(id, data, async = true) {
    const url = new URL(
      path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `file-upload/${id}/?asynchrone=${async}`),
      DOMAIN
    );
    const response = await axios.put(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  /** DELETE */
  async deleteFileUploadFile(id) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `file-upload/${id}/`), DOMAIN);
    const response = await axios.delete(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 204) {
      return response.data;
    }
    return false;
  },

  /** SCENARIO */
  async runFileUploadResourceScenario(id, scenarioId, data) {
    const url = new URL(
      path.join(
        `${i18n.locale}${RESOURCE_API_PATH}`,
        `file-upload/${id}/_scenarios/${scenarioId}/run/?asynchrone=true`
      ),
      DOMAIN
    );
    const response = await axios.post(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 202) {
      return response.data;
    }
    return false;
  },

  /** ANNEXES */
  async createFileUploadResourceAnnexe(id, data) {
    const url = new URL(
      path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `file-upload/${id}/?asynchrone=false`),
      DOMAIN
    );
    const response = await axios.put(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },
  // async updateFileUploadAnnexe(resourceId) {
  //   const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `resources/${resourceId}/`), DOMAIN);
  //   const response = await axios.patch(url, {}, { ...DEV_AUTH && { auth: AUTH } });
  //   if (response.status === 200) {
  //     return response.data;
  //   }
  //   return false;
  // },
};

export default fileUploadAPI;