import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import router from '@/router';

export class ErrorService {

  static onError(error, message, title) {

    toastr.options = {
      closeButton: true,
      debug: false,
      newestOnTop: true,
      progressBar: false,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '0',
      extendedTimeOut: '0',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut'
    };

    if (error) {
      const response = error.response;
      // Handle errors code
      if (response && response.status === 403) {
        router.push({ name: '403Page' });
      } else if (response && (response.status >= 400 && response.status < 405)) {
        const messages = [];
        if (!message) {

          const errorObj = response.data ? response.data : response;
          // eslint-disable-next-line no-inner-declarations
          function recurse(obj) {
            for (const [, value] of Object.entries(obj)) {
              if (typeof value === 'string') {
                messages.push(`${value}`);
              } else {
                recurse(value);
              }
            }
          }
          recurse(errorObj);

        } else {
          messages.push(message);
        }
        if (messages.length > 1) {
          toastr.error(`<ul>${messages.join('\n')}</ul>`, 'Erreur');
        } else {
          toastr.error(`${messages[0]}`, `${ title ? title : 'Erreur'}`);
        }
        return false;
      } else if (response && response.status === 500) {
        toastr.error('Erreur critique', response.status);
        return false;
      }
    } else if (!error && message) {
      toastr.error(message);
      return false;
    }
  }

  static onSuccess(response, message) {

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: true,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '5000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut'
    };

    if (response) {
      toastr.success(`${message}`);
      return false;
    }
  }
}
