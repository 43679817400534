import axios from 'axios';
import i18n from '@/i18n';

const DEV_AUTH = process.env.NODE_ENV === 'development' ? true : false;

const AUTH = {
  username: process.env.VUE_APP_API_ADMIN_USERNAME,
  password: process.env.VUE_APP_API_ADMIN_PASSWORD
};

const path = require('path');
const DOMAIN = process.env.VUE_APP_DOMAIN;
const RESOURCE_API_PATH = process.env.VUE_APP_RESOURCE_API_PATH;
const HREF_API_APTH = path.join(`${i18n.locale}${RESOURCE_API_PATH}`, 'href/');

const hrefAPI = {

  /** CREATE */
  async createHrefFormat(data) {
    const url = new URL(HREF_API_APTH, DOMAIN);
    const response = await axios.post(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 201) {
      return response.data;
    }
    return false;
  },

  /** UPDATE */
  async updateHrefFormat(id, data) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `href/${id}/`), DOMAIN);
    const response = await axios.put(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async updateHrefResource(resourceData, data, async = false) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `href/${resourceData.id}/`), DOMAIN);
    const response = await axios.patch(
      url,
      {
        href: resourceData.href,
        dataformat_id: resourceData.format.id
      },
      { ...DEV_AUTH && { auth: AUTH } }
    );
    const url2 = new URL(
      path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `href/${resourceData.id}/?asynchrone=${async}`),
      DOMAIN
    );
    const response2 = await axios.patch(url2, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response2.status === 200) {
      return response.data;
    }
    return false;
  },

  /** SCENARII */
  async getHrefResourceScenarios(id) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `href/${id}/_scenarios/`), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },
  async runHrefResourceScenario(id, scenarioId, data) {
    const url = new URL(
      path.join(
        `${i18n.locale}${RESOURCE_API_PATH}`,
        `href/${id}/_scenarios/${scenarioId}/run/?asynchrone=true`
      ),
      DOMAIN
    );
    const response = await axios.post(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 202) {
      return response.data;
    }
    return false;
  },

  /** SCHEDULERS */
  async setHrefScheduler(id, data) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `href/${id}/scenario-execution-frequency/`), DOMAIN);
    const response = await axios.post(
      url,
      data,
      { ...DEV_AUTH && { auth: AUTH } }
    );
    if (response.status === 201) {
      return response.data;
    }
    return false;
  },
  async updateHrefScheduler(resourceId, data, scenarioId) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `href/${resourceId}/scenario-execution-frequency/${scenarioId}/`), DOMAIN);
    const response = await axios.patch(
      url,
      data,
      { ...DEV_AUTH && { auth: AUTH } }
    );
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },
  async removeHrefScheduler(resourceId, scenarioId) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `href/${resourceId}/scenario-execution-frequency/${scenarioId}/`), DOMAIN);
    const response = await axios.delete(
      url,
      { ...DEV_AUTH && { auth: AUTH } }
    );
    if (response.status === 204) {
      return true;
    }
    return false;
  },
};

export default hrefAPI;