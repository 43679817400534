<template>
  <div id="app">
    <b-overlay
      id="authenticating-overlay"
      no-wrap
      :show="authenticating"
      variant="light"
      opacity="0.5"
      rounded="false"
    />
    <Header />
    <!-- use fullPath as key to refresh page on param change -->
    <!-- substring to NOT refresh on locale change -->
    <router-view
      v-if="!authenticating"
      :key="$route.fullPath.substring(3)"
    />
    <div
      v-else
      class="page"
    />
    <confirmation-modal />
    <progress-modal />
    <Footer />
  </div>
</template>

<script>
import ConfirmationModal from '@/components/Modals/ConfirmationModal.vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import ProgressModal from '@/components/Modals/ProgressModal.vue';

import { mapState, mapMutations, mapActions } from 'vuex';

export default {

  components : {
    ConfirmationModal,
    Header,
    Footer,
    ProgressModal
  },

  data() {
    return {
      authenticating: true
    };
  },

  computed: {
    ...mapState('user-login', [
      'isUserAuthenticated'
    ]),
  },

  async created() {
    this.CLOSE_MODALS();
    await this.GET_USER_AUTHENTICATION();
    await this.GET_USER_STATUSES();
    await this.GET_USERGROUP_STATUSES();
    this.authenticating = false;
  },

  mounted() {
    // Watch modal closing event
    this.$root.$on('bv::modal::hide', (e) => {
      this.CLOSE_MODAL(e.componentId.split('-')[0]);
    });
  },

  methods: {
    ...mapMutations('modal', [
      'CLOSE_MODAL',
      'CLOSE_MODALS'
    ]),
    ...mapActions('user-login', [
      'GET_USER_AUTHENTICATION'
    ]),
    ...mapActions('users', [
      'GET_USER_STATUSES'
    ]),
    ...mapActions('usergroups', [
      'GET_USERGROUP_STATUSES'
    ])
  }
};
</script>

<style>
#authenticating-overlay {
  z-index: 1103 !important;
  height: 100vh;
  width: 100vw;
}
</style>
