import axios from 'axios';
import i18n from '@/i18n';

const DEV_AUTH = process.env.NODE_ENV === 'development' ? true : false;

const AUTH = {
  username: process.env.VUE_APP_API_ADMIN_USERNAME,
  password: process.env.VUE_APP_API_ADMIN_PASSWORD
};

const path = require('path');
const DOMAIN = process.env.VUE_APP_DOMAIN;
const DASHBOARD_API_PATH = process.env.VUE_APP_DASHBOARD_API_PATH;

if (!DEV_AUTH) {
  axios.defaults.headers.common['X-CSRFToken'] = (name => {
    var re = new RegExp(name + '=([^;]+)');
    var value = re.exec(document.cookie);
    return (value != null) ? unescape(value[1]) : null;
  })('csrftoken');
}

const dashboardAPI = {

  async getTaskQueue(page = 1, datasetId, controller) {
    const url = new URL(
      path.join(
        `${i18n.locale}${DASHBOARD_API_PATH}`,
        `/task-queue-track/?page=${page}${datasetId ? `&object_class=dataset&object_id=${datasetId}` : ''}`
      ),
      DOMAIN
    );
    const response = await axios.get(url, {
      ...controller && { signal: controller.signal },
      ...DEV_AUTH && { auth: AUTH }
    });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async orderTaskQueue(direction, field, page = 1, datasetId, controller) {
    const url = new URL(
      path.join(
        `${i18n.locale}${DASHBOARD_API_PATH}`,
        `task-queue-track/?ordering=${direction}${field}&page=${page}${datasetId ? `&object_class=dataset&object_id=${datasetId}` : ''}`
      ),DOMAIN
    );
    const response = await axios.get(url, {
      ...controller && { signal: controller.signal },
      ...DEV_AUTH && { auth: AUTH }
    });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getTask(id) {
    const url = new URL(path.join(`${i18n.locale}${DASHBOARD_API_PATH}`, `task-queue-track/${id}/`), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getDatasetLastTasks(id, pageSize = 3) {
    const url = new URL(
      path.join(
        `${i18n.locale}${DASHBOARD_API_PATH}`,
        `task-queue-track/?object_class=dataset&object_id=${id}&page_size=${pageSize}`
      ),
      DOMAIN
    );
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data.results;
    }
    return false;
  },

  async getDatasetLogs(datasetId, object, page = 1) {
    const url = new URL(
      path.join(
        `${i18n.locale}${DASHBOARD_API_PATH}`,
        `log/?page=${page}&object_id=${datasetId}&object_class=${object}&with_related=true`
      ),
      DOMAIN
    );
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  }

};

export default dashboardAPI;
