import geoserverAPI from '@/api/resource/geoserverAPI.js';
import { ErrorService } from '@/services/error-service.js';
import i18n from '@/i18n';

/**************** STATE *******************/
const state = {
  workspaces: [],
  layers: [],
  layergroups: [],
  selectedWorkspace: null,

  currentGeoserver: null
};

/**************** GETTERS *****************/
const getters = {};

/*************** MUTATIONS ****************/
const mutations = {
  SET_WORKSPACES: (state, payload) => {
    if (payload.workspaces && payload.workspaces.workspace) {
      state.workspaces = payload.workspaces.workspace.sort((a, b) => a.name.localeCompare(b.name));
    } else {
      state.workspaces = [];
    }
  },

  SET_LAYERS: (state, payload) => {
    if (payload.layers && payload.layers.layer) {
      state.layers = payload.layers.layer.sort((a, b) => a.name.localeCompare(b.name));
    } else {
      state.layers = [];
    }
  },

  SET_LAYERGROUPS: (state, payload) => {
    if (payload.layerGroups && payload.layerGroups.layerGroup) {
      state.layergroups = payload.layerGroups.layerGroup.sort((a, b) => a.name.localeCompare(b.name));
    } else {
      state.layergroups = [];
    }
  },

  SET_SELECTED_WORKSPACE: (state, payload) => {
    state.selectedWorkspace = payload;
  },

  SET_CURRENT_GEOSERVER: (state, payload) => {
    state.currentGeoserver = payload;
  }
};
/**************** ACTIONS *****************/
const actions = {
  GET_WORKSPACES: async ({ commit }) => {
    const workspaces = await geoserverAPI.getWorkspaces();
    commit('SET_WORKSPACES', workspaces);
  },
  GET_LAYERS: async ({ state, commit }) => {
    const layers = await geoserverAPI.getWorkspaceLayers(state.selectedWorkspace.name);
    commit('SET_LAYERS', layers);
  },
  GET_LAYERGROUPS: async ({ state, commit }) => {
    const layergroups = await geoserverAPI.getWorkspaceLayergroups(state.selectedWorkspace.name);
    commit('SET_LAYERGROUPS', layergroups);
  },

  START_CREATE_DATA: async ({ commit }, { data, layerType }) => {
    const createdGeoserver = await geoserverAPI.createGeoserverSource(data);
    if (createdGeoserver && createdGeoserver.id) {
      commit('SET_CURRENT_GEOSERVER', createdGeoserver);

      // Get resource scenarios
      const scenarios = await geoserverAPI.getGeoserverResourceScenarios(layerType, createdGeoserver.id);
      if (scenarios && scenarios.length) {
        commit('resources/SET_CURRENT_RESOURCE_SCENARIOS', scenarios, { root: true });
      } else {
        ErrorService(null, i18n.messages[i18n.locale].messages.error.scenario.create);
      }
    }
  },
  FINISH_CREATE_DATA: async ({ state, commit }, { scenarioId, data, layerType }) => {
    // Run scenario
    await geoserverAPI.runGeoserverResourceScenario(layerType, state.currentGeoserver.id, scenarioId, data);
    ErrorService.onSuccess(
      state.currentGeoserver.id,
      `La création de la ressource <b>${state.currentGeoserver.name}</b> a été lancée avec succès.`
    );
    commit('SET_CURRENT_GEOSERVER', null);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
