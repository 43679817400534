import resourcesAPI from '@/api/resourcesAPI.js';
import ftpAPI from '@/api/resource/ftpAPI';
import { ErrorService } from '@/services/error-service.js';

/**************** STATE *******************/
const state = {
  ftpChoicesLoading: false,
  ftpChoices: [],

  currentFTP: null
};

/**************** GETTERS *******************/
const getters = {};

/**************** MUTATIONS *******************/
const mutations = {
  SET_FTP_CHOICES_LOADING: (state, payload) => {
    state.ftpChoicesLoading = payload;
  },
  SET_FTP_CHOICES: (state, payload) => {
    if (payload) {
      state.ftpChoices = payload;
    } else {
      state.ftpChoices = [];
    }
  },

  SET_CURRENT_FTP: (state, payload) => {
    state.currentFTP = payload;
  }
};

/**************** ACTIONS *******************/
const actions = {
  GET_FTP_CHOICES: async ({ commit }, usergroupId) => {
    commit('SET_FTP_CHOICES_LOADING', true);
    const response = await resourcesAPI.getFTPChoices(usergroupId);
    const ftpChoices = response.map(el => { return { ...el, checked: false }; });
    ftpChoices.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
    commit('SET_FTP_CHOICES', ftpChoices);
    commit('SET_FTP_CHOICES_LOADING', false);
  },

  START_CREATE_DATA: async ({ commit }, { data }) => {
    const createdFTP = await ftpAPI.createFTPFormat(data);
    commit('SET_CURRENT_FTP', createdFTP);

    return createdFTP.id;
  },
  FINISH_CREATE_DATA: async ({ state, commit }, { scenarioId, data }) => {
    // Run scenario
    await ftpAPI.runFTPResourceScenario(state.currentFTP.id, scenarioId, data);
    ErrorService.onSuccess(
      state.currentFTP.id,
      `La création de la ressource <b>${state.currentFTP.id.file.filename}</b> a été lancée avec succès.`
    );
    commit('SET_CURRENT_FTP', null);
  },

  START_MODIFY_DATA: async ({ commit }, { data }) => {    
    const updatedFTP = await ftpAPI.updateFTPFormat(data.id, { dataformat_id: data.format.id });
    commit('SET_CURRENT_FTP', updatedFTP);

    return updatedFTP.id;
  },
  FINISH_MODIFY_DATA: async ({ state, commit }, { scenarioId, data }) => {
    // Run scenario
    await ftpAPI.runFTPResourceScenario(state.currentFTP.id, scenarioId, data);
    ErrorService.onSuccess(
      state.currentFTP,
      `La mise à jour de la ressource <b>${state.currentFTP.resource.codename}</b> a été lancée avec succès.`
    );
    commit('SET_CURRENT_FTP', null);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};