import axios from 'axios';
import i18n from '@/i18n';

const DEV_AUTH = process.env.NODE_ENV === 'development' ? true : false;

const AUTH = {
  username: process.env.VUE_APP_API_ADMIN_USERNAME,
  password: process.env.VUE_APP_API_ADMIN_PASSWORD
};

const path = require('path');
const DOMAIN = process.env.VUE_APP_DOMAIN;
const RESOURCE_API_PATH = process.env.VUE_APP_RESOURCE_API_PATH;
const GEOSPATIAL_API_PATH = process.env.VUE_APP_GEOSPATIAL_API_PATH;

if (!DEV_AUTH) {
  axios.defaults.headers.common['X-CSRFToken'] = (name => {
    var re = new RegExp(name + '=([^;]+)');
    var value = re.exec(document.cookie);
    return (value != null) ? unescape(value[1]) : null;
  })('csrftoken');
}

const resourcesAPI = {
  async createResource(data) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, 'resources/'), DOMAIN);
    const response = await axios.post(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 201) {
      return response.data;
    }
    return false;
  },
  async getResource(id) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `resources/${id}/`), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getDatasetResources(datasetId) {
    const url = new URL(
      path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `resource-dataset/?dataset_id=${datasetId}`),
      DOMAIN
    );
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },
  async createResourceDatasetRelation(data) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, 'resource-dataset/'), DOMAIN);
    const response = await axios.post(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 201) {
      return response.data;
    }
    return false;
  },
  async getResourceDatasetRelation(id) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `resource-dataset/${id}/`), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },
  async getResourceToDatasetScenarios(id) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `resource-dataset/${id}/_scenarios/`), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },
  async buildResourceIndex(id, scenarioId, datasetId, asynchrone = true) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `resource-dataset/${id}/_scenarios/${scenarioId}/run/?asynchrone=${asynchrone}`), DOMAIN);
    const response = await axios.post(url, {
      kwargs: {
        target: {
          model: 'dataset',
          app_label: 'onegeo_dataset',
          pk: datasetId
        }
      }
    }, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 202) {
      return response.data;
    }
    return false;
  },

  async patchResource(id, data) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `resources/${id}/`), DOMAIN);
    const response = await axios.patch(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async patchResourceGeographicLayer(url, data) {
    const response = await axios.patch(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getResourceGeographicLayerScenarios(id) {
    const url = new URL(path.join(`${i18n.locale}${GEOSPATIAL_API_PATH}`, `/geographiclayer/${id}/_scenarios/`), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },
  async runResourceGeographicLayerScenario(id, scenarioId) {
    const url = new URL(path.join(`${i18n.locale}${GEOSPATIAL_API_PATH}`, `/geographiclayer/${id}/_scenarios/${scenarioId}/run/?asynchrone=false`), DOMAIN);
    const response = await axios.post(url, null, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 202) {
      return response.data;
    }
    return false;
  },

  async deleteResourceToDataset(id) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `resource-dataset/${id}/`), DOMAIN);
    const response = await axios.delete(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 204) {
      return response.data;
    }
    return false;
  },

  async deleteResource(id) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `resources/${id}/`), DOMAIN);
    const response = await axios.delete(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 204) {
      return response.data;
    }
    return false;
  },

  async updateResource(id, data) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `resources/${id}/`), DOMAIN);
    const response = await axios.put(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getResourceDataFormats() {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, 'data-format/'), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getResourceData(location) {
    const url = new URL(location, DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getResourceExecutionFrequency(location) {
    const url = new URL(location, DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getFTPChoices(usergroupId) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `ftp-choices/?usergroup_id=${usergroupId}`), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getResourceKinds() {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, 'kinds/'), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getResourceScenarios(slug, resourceId) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `${slug}/${resourceId}/_scenarios/`), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },
};

export default resourcesAPI;
