import axios from 'axios';
import i18n from '@/i18n';

const DEV_AUTH = process.env.NODE_ENV === 'development' ? true : false;

const AUTH = {
  username: process.env.VUE_APP_API_ADMIN_USERNAME,
  password: process.env.VUE_APP_API_ADMIN_PASSWORD
};

const path = require('path');
const DOMAIN = process.env.VUE_APP_DOMAIN;
const RESOURCE_API_PATH = process.env.VUE_APP_RESOURCE_API_PATH;
const FTP_API_APTH = path.join(`${i18n.locale}${RESOURCE_API_PATH}`, 'ftp/');

const ftpAPI = {
  /** CREATE */
  async createFTPFormat(data) {
    const url = new URL(FTP_API_APTH, DOMAIN);
    const response = await axios.post(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 201) {
      return response.data;
    }
    return false;
  },

  async createFTPResource(id, data, async = true) {
    const url = new URL(
      path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `ftp/${id}/?asynchrone=${async}`),
      DOMAIN
    );
    const response = await axios.put(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  /** UPDATE */
  async updateFTPFormat(id, format) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `ftp/${id}/`), DOMAIN);
    const response = await axios.patch(url, format, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  // async updateFTPResource(resourceData, data, async = true) {
  //   const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `ftp/${resourceData.id}/`), DOMAIN);
  //   const response = await axios.patch(
  //     url,
  //     { href: resourceData.selectedFtpFiles.map(el => el.value) },
  //     { ...DEV_AUTH && { auth: AUTH } }
  //   );
  //   const url2 = new URL(
  //     path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `ftp/${resourceData.id}/?asynchrone=${async}`),
  //     DOMAIN
  //   );
  //   const response2 = await axios.patch(url2, { kwargs: data.kwargs }, { ...DEV_AUTH && { auth: AUTH } });
  //   if (response2.status === 200) {
  //     return response.data;
  //   }
  //   return false;
  // },

  /** SCENARII */
  async getFTPResourceScenarios(id) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `ftp/${id}/_scenarios/`), DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },
  async runFTPResourceScenario(id, scenarioId, data) {
    const url = new URL(
      path.join(
        `${i18n.locale}${RESOURCE_API_PATH}`,
        `ftp/${id}/_scenarios/${scenarioId}/run/?asynchrone=true`
      ),
      DOMAIN
    );
    const response = await axios.post(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 202) {
      return response.data;
    }
    return false;
  },

  /** SCHEDULER */
  async setFTPScheduler(id, data) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `ftp/${id}/scenario-execution-frequency/`), DOMAIN);
    const response = await axios.post(
      url,
      data,
      { ...DEV_AUTH && { auth: AUTH } }
    );
    if (response.status === 201) {
      return response.data;
    }
    return false;
  },
  async getFTPScheduler(id) {
    const url = new URL(path.join(`${i18n.locale}${RESOURCE_API_PATH}`, `ftp/${id}/scenario-execution-frequency/`), DOMAIN);
    const response = await axios.get(
      url,
      { ...DEV_AUTH && { auth: AUTH } }
    );
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },
};

export default ftpAPI;