import fileUploadAPI from '@/api/resource/file-uploadAPI';
import { ErrorService } from '@/services/error-service.js';

/**************** STATE *******************/
const state = {
  currentFileUpload: null
};

/**************** GETTERS *******************/
const getters = {};

/**************** MUTATIONS *******************/
const mutations = {
  SET_CURRENT_FILE_UPLOAD: (state, payload) => {
    state.currentFileUpload = payload;
  }
};

/**************** ACTIONS *******************/
const actions = {
  START_CREATE_DATA: async ({ commit }, { data }) => {
    commit('modal/OPEN_MODAL', {
      modal: 'progress',
      title: '',
      content: ''
    }, { root: true });
    
    const resp1 = await fileUploadAPI.createFileUploadFormat({
      dataformat_id: data.dataformat_id,
      resource_id: data.resource_id,
    });
    const createdFileUpload = await fileUploadAPI.createFileUploadFile(resp1.id, data.file);
    commit('modal/CLOSE_MODAL', 'progress', { root: true });
    commit('SET_PROGRESS_PERCENTAGE', 0, { root: true });
    commit('SET_CURRENT_FILE_UPLOAD', createdFileUpload);

    return createdFileUpload.id;
  },
  FINISH_CREATE_DATA: async ({ state, commit }, { scenarioId, data }) => {
    // Run scenario
    await fileUploadAPI.runFileUploadResourceScenario(state.currentFileUpload.id, scenarioId, data);
    ErrorService.onSuccess(
      state.currentFileUpload.id,
      `La création de la ressource <b>${state.currentFileUpload.file.filename}</b> a été lancée avec succès.`
    );
    commit('SET_CURRENT_FILE_UPLOAD', null);
  },
  
  START_MODIFY_DATA: async ({ commit }, { data }) => {
    commit('modal/OPEN_MODAL', {
      modal: 'progress',
      title: '',
      content: ''
    }, { root: true });
    
    await fileUploadAPI.updateFileUploadFormat(
      data.location,
      { dataformat_id: data.format.id }
    );
    const updatedFileUpload = await fileUploadAPI.updateFileUploadFile(data.location, data.file);
    commit('modal/CLOSE_MODAL', 'progress', { root: true });
    commit('SET_PROGRESS_PERCENTAGE', 0, { root: true });
    commit('SET_CURRENT_FILE_UPLOAD', updatedFileUpload);

    return updatedFileUpload.id;
  },
  FINISH_MODIFY_DATA: async ({ state, commit }, { scenarioId, data }) => {
    // Run scenario
    await fileUploadAPI.runFileUploadResourceScenario(state.currentFileUpload.id, scenarioId, data);
    ErrorService.onSuccess(
      state.currentFileUpload,
      `La mise à jour de la ressource <b>${state.currentFileUpload.file.filename}</b> a été lancée avec succès.`
    );
    commit('SET_CURRENT_FILE_UPLOAD', null);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};