import fileDownloadAPI from '@/api/resource/file-downloadAPI';
import { ErrorService } from '@/services/error-service.js';
import i18n from '@/i18n';

/**************** STATE *******************/
const state = {
  currentFileDownload: null
};

/**************** GETTERS *******************/
const getters = {};

/**************** MUTATIONS *******************/
const mutations = {
  SET_CURRENT_FILE_DOWNLOAD: (state, payload) => {
    state.currentFileDownload = payload;
  }
};

/**************** ACTIONS *******************/
const actions = {
  START_CREATE_DATA: async ({ commit }, { data }) => {
    const createdFileDownload = await fileDownloadAPI.createFileDownloadFormat(data);
    // const createdFileDownload = await fileDownloadAPI.createFileDownloadResource(resp1.id, data.file);
    commit('SET_CURRENT_FILE_DOWNLOAD', createdFileDownload);

    return createdFileDownload.id;
  },
  FINISH_CREATE_DATA: async ({ state, commit }, { scenarioId, data }) => {
    // Run scenario
    await fileDownloadAPI.runFileDownloadResourceScenario(state.currentFileDownload.id, scenarioId, data);
    ErrorService.onSuccess(
      state.currentFileDownload.id,
      i18n.messages[i18n.locale].messages.success.resource.created
        .replace('{name}', state.currentFileDownload.resource.codename)
    );
    commit('SET_CURRENT_FILE_DOWNLOAD', null);
  },

  START_MODIFY_DATA: async ({ commit }, { data }) => {    
    const updatedFileDownload = await fileDownloadAPI.updateFileDownloadResource(data.id, { dataformat_id: data.format.id });
    commit('SET_CURRENT_FILE_DOWNLOAD', updatedFileDownload);

    return updatedFileDownload.id;
  },
  FINISH_MODIFY_DATA: async ({ state, commit }, { scenarioId, data }) => {
    // Run scenario
    await fileDownloadAPI.runFileDownloadResourceScenario(state.currentFileDownload.id, scenarioId, data);
    ErrorService.onSuccess(
      state.currentFileDownload,
      i18n.messages[i18n.locale].messages.success.resource.updated
        .replace(
          '{name}', 
          state.currentFileDownload.resource.display_name ?
            state.currentFileDownload.resource.display_name : ''
        ),
    );
    commit('SET_CURRENT_FILE_DOWNLOAD', null);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};