/**************** STATE *******************/
const state = {
  confirmation: {
    open: false,
    title: null,
    content: null,
    trigger: () => {}
  },
  progress: {
    open: false,
    title: null,
    content: null,
  }
};

/**************** GETTERS *****************/
const getters = {};

/*************** MUTATIONS ****************/
const mutations = {
  OPEN_MODAL: (state, payload) => {
    switch (payload.modal) {
    case 'confirmation':
      state.confirmation.title = payload.title;
      state.confirmation.content = payload.content;
      state.confirmation.trigger = payload.trigger;
      state.confirmation.open = true;
      break;

    case 'progress':
      state.progress.title = payload.title;
      state.progress.content = payload.content;
      state.progress.open = true;
      break;
    }
  },
  CLOSE_MODAL: (state, payload) => {
    switch (payload) {
    case 'confirmation':
      state.confirmation.title = null;
      state.confirmation.content = null;
      state.confirmation.trigger = () => {};
      state.confirmation.open = false;
      break;
    case 'progress':
      state.progress.title = null;
      state.progress.content = null;
      state.progress.open = false;
      break;
    }
  },

  CLOSE_MODALS: (state) => {
    for (const modal in state) {
      switch (modal) {
      case 'confirmation':
        state.confirmation.title = null;
        state.confirmation.content = null;
        state.confirmation.trigger = () => {};
        state.confirmation.open = false;
        break;

      case 'progress':
        state.progress.title = null;
        state.progress.content = null;
        state.progress.open = false;
        break;
      }
    }
  },
  TRIGGER_MODAL: (state, payload) => {
    state[payload.modal].trigger();
    switch (payload.modal) {
    case 'confirmation':
      state.confirmation.title = null;
      state.confirmation.content = null;
      state.confirmation.trigger = () => {};
      state.confirmation.open = false;
      break;

    case 'progress':
      state.progress.title = null;
      state.progress.content = null;
      state.progress.open = false;
      break;
    }
  }
};
/**************** ACTIONS *****************/
const actions = {
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
