import hrefAPI from '@/api/resource/hrefAPI.js';
import { ErrorService } from '@/services/error-service.js';

/**************** STATE *******************/
const state = {
  currentHref: null
};

/**************** GETTERS *******************/
const getters = {};

/**************** MUTATIONS *******************/
const mutations = {
  SET_CURRENT_HREF: (state, payload) => {
    state.currentHref = payload;
  }
};

/**************** ACTIONS *******************/
const actions = {
  START_CREATE_DATA: async ({ commit }, { data }) => {
    const createdHref = await hrefAPI.createHrefFormat(data);
    commit('SET_CURRENT_HREF', createdHref);

    return createdHref.id;
  },
  FINISH_CREATE_DATA: async ({ state, commit }, { scenarioId, data }) => {
    // Run scenario
    await hrefAPI.runHrefResourceScenario(state.currentHref.id, scenarioId, data);
    ErrorService.onSuccess(
      state.currentHref.id,
      `La création de la ressource <b>${
        state.currentHref.resource.display_name ? state.currentHref.resource.display_name : ''
      }</b> a été lancée avec succès.`
    );
    commit('SET_CURRENT_HREF', null);
  },

  START_MODIFY_DATA: async ({ commit }, { data }) => {    
    const updatedHref = await hrefAPI.updateHrefFormat(data.id, { dataformat_id: data.format.id });
    commit('SET_CURRENT_HREF', updatedHref);

    return updatedHref.id;
  },
  FINISH_MODIFY_DATA: async ({ state, commit }, { scenarioId, data }) => {
    // Run scenario
    await hrefAPI.runHrefResourceScenario(state.currentHref.id, scenarioId, data);
    ErrorService.onSuccess(
      state.currentHref,
      `La mise à jour de la ressource <b>${
        state.currentHref.resource.display_name ? state.currentHref.resource.display_name : ''
      }</b> a été lancée avec succès.`
    );
    commit('SET_CURRENT_HREF', null);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};