import axios from 'axios';
import i18n from '@/i18n';

const DEV_AUTH = process.env.NODE_ENV === 'development' ? true : false;

const AUTH = {
  username: process.env.VUE_APP_API_ADMIN_USERNAME,
  password: process.env.VUE_APP_API_ADMIN_PASSWORD
};

const DOMAIN = process.env.VUE_APP_DOMAIN;
const DATASTORES_API_PATH = '/{0}/datastores/';

if (!DEV_AUTH) {
  axios.defaults.headers.common['X-CSRFToken'] = (name => {
    var re = new RegExp(name + '=([^;]+)');
    var value = re.exec(document.cookie);
    return (value != null) ? unescape(value[1]) : null;
  })('csrftoken');
}

const datastoreAPI = {

  /** GET */
  async getDatastoreList(datastoreType) {
    const url = new URL(`${i18n.locale}${DATASTORES_API_PATH.format(datastoreType)}databases/`, DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  async getTablesList(tableUrl) {
    const response = await axios.get(`${tableUrl}?free=true`, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  /** CREATE */
  async createDatastore(datastoreType, data) {
    const url = new URL(`${i18n.locale}${DATASTORES_API_PATH.format(datastoreType.type)}${datastoreType.value}/`, DOMAIN);
    const response = await axios.post(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 201) {
      return response.data;
    }
    return false;
  },
  async createDatastoreResource(id, datastoreType, data, async = true) {
    const url = new URL(
      `${i18n.locale}${DATASTORES_API_PATH.format(datastoreType.type)}${datastoreType.value}/${id}/?asynchrone=${async}`,
      DOMAIN
    );
    const response = await axios.patch(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  /** UPDATE */
  async updateDatastore(resourceId, datastoreType, data) {
    const url = new URL(`${i18n.locale}${DATASTORES_API_PATH.format(datastoreType.type)}${datastoreType.value}/${resourceId}/`, DOMAIN);
    const response = await axios.put(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },
  async updateDatastoreResource(id, datastoreType, data, async = true) {
    const url = new URL(
      `${i18n.locale}${DATASTORES_API_PATH.format(datastoreType.type)}${datastoreType.value}/${id}/?asynchrone=${async}`,
      DOMAIN
    );
    const response = await axios.patch(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },

  /** SCENARII */
  async getDatastoreUpdateScenarios(datastoreType, id) {
    const url = new URL(`${i18n.locale}${DATASTORES_API_PATH.format(datastoreType.type)}${datastoreType.value}/${id}/`, DOMAIN);
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },
  async getDatastoreResourceScenarios(datastoreType, id) {
    const url = new URL(
      `${i18n.locale}${DATASTORES_API_PATH.format(datastoreType.type)}${datastoreType.value}/${id}/_scenarios/`,
      DOMAIN
    );
    const response = await axios.get(url, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 200) {
      return response.data;
    }
    return false;
  },
  async runDatastoreResourceScenario(datastoreType, id, scenarioId, data) {
    const url = new URL(
      `${i18n.locale}${DATASTORES_API_PATH.format(datastoreType.type)}${datastoreType.value}/${id}/_scenarios/${scenarioId}/run/?asynchrone=true`,
      DOMAIN
    );
    const response = await axios.post(url, data, { ...DEV_AUTH && { auth: AUTH } });
    if (response.status === 202) {
      return response.data;
    }
    return false;
  },
};

export default datastoreAPI;